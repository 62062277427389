@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-secondary text-primary;
  }
  input::placeholder {
    @apply text-primary;
  }
  .title-text{
    @apply text-[60px] 
  }
  .h2 {
    @apply font-primary text-[45px] mb-4;
  }
  .h3 {
    @apply text-2xl font-primary font-semibold tracking-[1px] mb-2;
  }
  .btn {
    @apply text-[15px] tracking-[3px] font-tertiary uppercase text-white flex-1 transition-all px-8 flex justify-center items-center;
  }
  .btn-sm {
    @apply h-[48px];
  }
  .btn-lg {
    @apply h-[60px];
  }
  .btn-primary {
    @apply bg-accent hover:bg-accent-hover;
  }
  .btn-secondary {
    @apply bg-primary hover:bg-accent;
  }
  .btn-white {
    @apply bg-white text-[#0a0a0a];
  }
  .btn-red{
    @apply  bg-red-700 hover:bg-red-600 font-primary rounded-none
  }
  .default-button {
    @apply max-md:text-sm lg:uppercase py-4 btn btn-primary bg-rose-700 hover:shadow-lg hover:bg-rose-600
  }
  .up-effect {
    @apply transform transition duration-500 hover:-translate-y-6
  }
  .short-up-effect {
    @apply transform transition duration-500 hover:-translate-y-3
  }
  .gray-background {
    @apply bg-[#f3f4f6]
  }
  .img-default{
    @apply w-full h-full object-cover cursor-pointer
  }
  .img-content {
   @apply w-full h-full object-cover transition hover:scale-110 duration-300
  }
  .box-responsive {
    @apply grid grid-cols-1 lg:grid-cols-2
  }
  .banner-config{
    @apply h-[500px] bg-[#f3f4f6] bg-cover bg-center object-cover
  }
  .banner-title {
    @apply leading-tight lg:text-[50px] text-4xl font-bold drop-shadow-lg shadow-black
  }
  .loader{
    @apply h-screen w-full flex justify-center items-center
  }
  .title-details {
    @apply leading-tight lg:title-text max-md:text-[36px] font-bold drop-shadow-lg shadow-black
  }
 .centered {
  @apply flex justify-center items-center 
 }

 /*::::GESTION DES CLASSES:::*/

/*:::: MENU Option:: */
  .MENU{
    @apply text-base
  }
  
/*--->BANNER Large Tilte :: */
  .BANL{
    @apply text-6xl 
  }
  /* Medium */
  .BANM {
    @apply text-2xl
  }
  /* Small */
  .BANS
  {
    @apply text-lg
  }

/*::::BLACK Large title ::*/
  .S1BL
  {
    @apply text-black text-6xl
  }
    /* MEDIUM title */
  .S1BM
  {
    @apply text-black text-4xl
  }
    /* SMALL title */
  .S1BS
  {
    @apply text-black text-xl
  }
    /* REGULAR text */
  .S1BR
  {
    @apply text-black text-base
  }
    /* BOLD text */
  .S1BB
  {
    @apply text-black text-xl font-semibold
  }
  /* FOOTER text */
  .S1BF
  {
    @apply text-black text-base
  }

/* ::::WHITE LARGE Title:: */
  .S1WL
  {
    @apply text-white text-6xl
  }
    /* MEDIUM title */
  .S1WM
  {
    @apply text-white text-4xl
  }
  .S1WS
  {
    @apply text-white text-xl
  }
  .S1WR
  {
    @apply text-white text-base
  }
  .S1WB
  {
    @apply text-white text-xl font-semibold
  }
  .S1WF
  {
    @apply text-white text-xl
  }

/*::::RED Large Title:: */
  .S1RL
  {
    @apply text-rose-700 text-6xl ;
  }
    /* MEDIUM title */
  .S1RM
  {
    @apply text-rose-700 text-4xl
  }
    /* SMALL Title */
  .S1RS
  {
    @apply text-rose-700 text-xl
  }
   /* REGULAR Text */
  .S1RR
  {
    @apply text-rose-700 text-base
  }
  /* BOLD Text */
  .S1RB
  {
    @apply text-rose-700 text-xl font-semibold
  }
  /* FOOTER Text */
  .S1RF
  {
    @apply text-rose-700 text-xl
  }

/*::::GREY Large Text:: --*/
  .S1GL
  {
    @apply text-gray-500 text-6xl
  }
    /* MEDIUM title */
  .S1GM
  {
    @apply text-gray-500 text-4xl
  }
  /* SMALL Title */
  .S1GS
  {
    @apply text-gray-500 text-xl
  }
  /* REGULAR Text */
  .S1GR
  {
    @apply text-gray-500 text-base
  }
  /* BOLD Text */
  .S1GB
  {
    @apply text-gray-500 text-xl font-semibold
  }
  /* FOOTER Text */
 .S1GF
 {
  @apply text-gray-500 text-xl
 }

}
