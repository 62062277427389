
/* input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */
.font-responsive{
  font-size:10vw
};
.p-reponsive{
  font-size:5vw
}
.font-playfair
{
    font-family: 'Playfair Display', serif;
}
.font-roboto
{
    font-family: 'Roboto', sans-serif;
};
.font-opens
{
    font-family: 'Open Sans', sans-serif;
};
.logo {
    display: flex;
    width: 50%;
}
.text-box 
{
    padding: 64px 74px;
    height: auto;
}
.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
}
.shadow-shorter {
    box-shadow: 0 1px 1px rgba(0,0,0,0.11), 
                0 2px 2px rgba(0,0,0,0.11), 
                0 4px 4px rgba(0,0,0,0.11), 
                0 6px 8px rgba(0,0,0,0.11),
                0 8px 16px rgba(0,0,0,0.11);
  }
  .card-shadow {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    }
  .line-effect {
    color: #18272F;
    position: relative;
    text-decoration: none;
  }
  
  .line-effect::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #ca003b;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
  }
  
  .line-effect:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
  .block-thirty-eight {
    /* position: relative; */
    filter: drop-shadow(0 14px 14px rgba(0,0,0,.04));
    background-color: #fff;
    border: 2px solid #f3f3f3;
    padding: 45px 30px 46px;
    
};


